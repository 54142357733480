function setupPartsTableFilter() {
	// Get references to the search input and parts table
	const searchInput = document.getElementById( 'searchInput' );
	const partsTable  = document.getElementById( 'partsTable' );
	if ( partsTable ) {
		const partsEntries = partsTable.getElementsByClassName( 'parts-table__entry' );

		// Create a copy of the original state of the table
		const originalState = Array.from( partsEntries ).map( ( entry ) => ({
			entry,
			display: entry.style.display,
		}) );

		// Add an event listener to the search input for real-time filtering
		searchInput.addEventListener( 'input', () => {
			const searchTerm = searchInput.value.toLowerCase();

			// Loop through each part's table entry and hide/show based on the search term
			// eslint-disable-next-line no-unused-vars
			Array.from( partsEntries ).forEach( ( entry, index ) => {
				const partName   = entry.querySelector( '.parts-table__name span' ).textContent.toLowerCase();
				const partNumber = entry.querySelector( '.parts-table__number span' ).textContent.toLowerCase();

				// Check if the search term is found in either the part name or part number
				if ( partName.includes( searchTerm ) || partNumber.includes( searchTerm ) ) {
					entry.style.display = 'block'; // Show the entry
				} else {
					entry.style.display = 'none'; // Hide the entry
				}
			} );

			// Check if the search input is empty, and if so, reset the table to its original state
			if ( searchTerm === '' ) {
				originalState.forEach( ( item ) => {
					item.entry.style.display = item.display;
				} );
			}
		} );

	}
}

// Call the setupPartsTableFilter function when the DOM is loaded
document.addEventListener( 'DOMContentLoaded', setupPartsTableFilter );

function initializeSorting() {
	let ascendingOrder = true; // Initially, set the sorting order to ascending

	// Function to sort items based on the "Status" value
	function sortItemsByStatus() {
		const rentalsContainer = document.querySelector( '.rentals__heading' ); // Assuming there's a container div for rentals

		// Collect all rental items
		const rentalItems = Array.from( rentalsContainer.nextElementSibling.querySelectorAll( '.rentals__item' ) );

		// Sort the items based on the "Status" value
		rentalItems.sort( ( a, b ) => {
			const statusA = a.querySelector( '.status' ).textContent.trim();
			const statusB = b.querySelector( '.status' ).textContent.trim();

			// Compare and sort based on status values
			const comparison = statusA.localeCompare( statusB );

			// Toggle sorting order
			return ascendingOrder ? comparison : -comparison;
		} );

		// Clear the existing items
		const parent = rentalsContainer.nextElementSibling;
		while ( parent.firstChild ) {
			parent.removeChild( parent.firstChild );
		}

		// Append the sorted items back to the container
		rentalItems.forEach( ( item ) => {
			parent.appendChild( item );
		} );

		// Toggle the sorting order for the next click
		ascendingOrder = !ascendingOrder;
	}

	// Attach a click event listener to the "Status" label
	const filterStatusLabel = document.querySelector( '#filterStatus' );
	if ( filterStatusLabel ) {
		filterStatusLabel.addEventListener( 'click', sortItemsByStatus );
	}
}

// Attach the sorting functionality when the DOM is loaded
document.addEventListener( 'DOMContentLoaded', initializeSorting );

import Splide from '@splidejs/splide';
// import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

export const basicSplide = () => {

	// Slide sliders
	const industriesSplide     = document.getElementById( 'industries-splide' );
	const brandsSplide         = document.getElementById( 'brands-splide' );
	const testimonialsSplide   = document.getElementById( 'testimonials-splide' );
	const productGallerySplide = document.getElementById( 'product-gallery-splide' );
	const masonryGallerySplide = document.getElementById( 'masonry-gallery-splide' );

	if ( industriesSplide ) {
		var splideIndustries = new Splide( '#industries-splide', {
			type       : 'loop',
			pagination : false,
			perPage    : 5,
			perMove    : 1,
			gap        : '1rem',
			breakpoints: {
				992: {
					perPage: 4,
					gap    : '1rem',
				},
				768: {
					perPage: 3,
					gap    : '1rem',
				},
				390: {
					perPage: 2,
					gap    : '1rem',
				},
			},
		} );

		splideIndustries.mount();
	}

	if ( brandsSplide ) {
		var splideBrands = new Splide( '#brands-splide', {
			type       : 'loop',
			pagination : true,
			perPage    : 4,
			perMove    : 1,
			gap        : '5rem',
			autoplay   : true,
			arrows     : true,
			breakpoints: {
				992: {
					perPage: 4,
					gap    : '1rem',
				},
				768: {
					perPage: 3,
					gap    : '1rem',
				},
				390: {
					perPage: 2,
					gap    : '1rem',
				},
			},
		} );

		splideBrands.mount();
	}

	if ( testimonialsSplide ) {
		var splideTestimonials = new Splide( '#testimonials-splide', {
			type      : 'loop',
			pagination: true,
			perPage   : 1,
			perMove   : 1,
			arrows    : false,
			//			gap        : '5rem',
			//			autoplay: true,
			//			breakpoints: {
			//				992: {
			//					perPage: 4,
			//					gap    : '1rem',
			//				},
			//				768: {
			//					perPage: 3,
			//					gap    : '1rem',
			//				},
			//				390: {
			//					perPage: 2,
			//					gap    : '1rem',
			//				},
			//			},
		} );

		splideTestimonials.mount();
	}

	if ( productGallerySplide ) {
		var main = new Splide( '#product-gallery-splide', {
			type       : 'loop',
			fixedHeight: 400,
			heightRatio: 0.5,
			pagination : false,
			arrows     : false,
			cover      : true,
			autoplay   : false,

			breakpoints: {
				992: {
//					perPage: 4,
//					gap    : '1rem',
				},
				768: {
//					perPage: 3,
//					gap    : '1rem',
				},
				390: {
//					perPage: 2,
//					gap    : '1rem',
					pagination: true,
				},
			},
		} );

		var thumbnails = new Splide( '#thumbnail-navigation', {
			arrows      : true,
			rewind      : true,
			fixedWidth  : 85,
			fixedHeight : 62,
			isNavigation: true,
			gap         : 10,
			//			focus           : 'center',
			pagination      : false,
			cover           : true,
			dragMinThreshold: {
				mouse: 4,
				touch: 10,
			},
			breakpoints     : {
				640: {
					fixedWidth : 66,
					fixedHeight: 38,
				},
			},
		} );

		main.sync( thumbnails );
		main.mount();
		thumbnails.mount();
	}

	if ( masonryGallerySplide ) {
		var splideMasonryGallery = new Splide( '#masonry-gallery-splide', {
			type      : 'loop',
			pagination: false,
			perPage   : 1,
			perMove   : 1,
			gap       : '1rem',
			autoplay  : true,
			arrows    : false,
		} );

		splideMasonryGallery.mount();
	}
};

function initializeFAQ() {
	// Get all question elements
	const questions = document.querySelectorAll( '.question' );
	// Get all answer elements
	const answers   = document.querySelectorAll( '.answer' );
	// Get all FAQ elements
	const faqs      = document.querySelectorAll( '.frequently-asked-questions__faqs__faq' );

	// Add click event listeners to each question
	questions.forEach( ( question, index ) => {
		question.addEventListener( 'click', () => {
			// Close all answers
			answers.forEach( ( answer, i ) => {
				if ( i !== index ) {
					answer.style.display = 'none';
					// Remove the "active" class from other FAQ elements
					faqs[ i ].classList.remove( 'active' );
					// Reset the accordion icon to "+" and the color to the default
					const accordionIcon       = faqs[ i ].querySelector( '.accordion-icon' );
					accordionIcon.innerHTML   = '+';
					accordionIcon.style.color = 'black';
				}
			} );

			// Toggle the visibility of the clicked answer
			const answer         = question.nextElementSibling;
			answer.style.display = answer.style.display === 'block' ? 'none' : 'block';

			// Add the "active" class to the clicked FAQ element
			faqs[ index ].classList.toggle( 'active' );

			// Update the accordion icon to "-" and change the color when the FAQ is active
			const accordionIcon = faqs[ index ].querySelector( '.accordion-icon' );
			if ( faqs[ index ].classList.contains( 'active' ) ) {
				accordionIcon.innerHTML   = '-';
				accordionIcon.style.color = '#1757a7';
			} else {
				accordionIcon.innerHTML   = '+';
				accordionIcon.style.color = 'black';
			}
		} );
	} );
}

document.addEventListener( 'DOMContentLoaded', initializeFAQ );

function initializeMegaMenus() {
	// Function to check screen width
	function isScreenLarge() {
		return window.innerWidth > 1200;
	}

	// Get the necessary elements
	const liHasMegaServices      = document.querySelector( '.has-mega-services' );
	const servicesMega           = document.getElementById( 'servicesMega' );
	const liHasMegaRentals       = document.querySelector( '.has-mega-rentals' );
	const rentalsMega            = document.getElementById( 'rentalsMega' );
	const liHasMegaInstallations = document.querySelector( '.has-mega-installations' );
	const installationsMega      = document.getElementById( 'installationsMega' );

	// Function to show mega menu and add class
	function showMegaMenu( menuElement, triggerElement ) {
		menuElement.style.display = 'block';
		triggerElement.classList.add( 'has-mega-menu-open' ); // Add the class
	}

	// Function to hide mega menu and remove class
	function hideMegaMenu( menuElement, triggerElement ) {
		menuElement.style.display = 'none';
		triggerElement.classList.remove( 'has-mega-menu-open' ); // Remove the class
	}

	// Function to hide all mega menus except the specified one
	function hideOtherMegaMenus( exceptMenuElement ) {
		const megaMenus = [servicesMega, rentalsMega, installationsMega];
		megaMenus.forEach( ( menu ) => {
			if ( menu !== exceptMenuElement ) {
				hideMegaMenu( menu, menu.parentElement );
			}
		} );
	}

	// Add event listeners for li.has-mega-services if screen is large
	if ( isScreenLarge() ) {
		liHasMegaServices.addEventListener( 'mouseover', () => {
			hideOtherMegaMenus( servicesMega );
			showMegaMenu( servicesMega, liHasMegaServices );
		} );

		servicesMega.addEventListener( 'mouseover', () => {
			showMegaMenu( servicesMega, liHasMegaServices );
		} );

		liHasMegaServices.addEventListener( 'mouseleave', () => {
			hideMegaMenu( servicesMega, liHasMegaServices );
		} );

		// Add event listeners for the servicesMega menu itself
		servicesMega.addEventListener( 'mouseleave', () => {
			hideMegaMenu( servicesMega, liHasMegaServices );
		} );
	}

	// Add event listeners for li.has-mega-rentals if screen is large
	if ( isScreenLarge() ) {
		liHasMegaRentals.addEventListener( 'mouseover', () => {
			hideOtherMegaMenus( rentalsMega );
			showMegaMenu( rentalsMega, liHasMegaRentals );
		} );

		rentalsMega.addEventListener( 'mouseover', () => {
			showMegaMenu( rentalsMega, liHasMegaRentals );
		} );

		liHasMegaRentals.addEventListener( 'mouseleave', () => {
			hideMegaMenu( rentalsMega, liHasMegaRentals );
		} );

		// Add event listeners for the rentalsMega menu itself
		rentalsMega.addEventListener( 'mouseleave', () => {
			hideMegaMenu( rentalsMega, liHasMegaRentals );
		} );
	}

	// Add event listeners for li.has-mega-installations if screen is large
	if ( isScreenLarge() ) {
		liHasMegaInstallations.addEventListener( 'mouseover', () => {
			hideOtherMegaMenus( installationsMega );
			showMegaMenu( installationsMega, liHasMegaInstallations );
		} );

		installationsMega.addEventListener( 'mouseover', () => {
			showMegaMenu( installationsMega, liHasMegaInstallations );
		} );

		liHasMegaInstallations.addEventListener( 'mouseleave', () => {
			hideMegaMenu( installationsMega, liHasMegaInstallations );
		} );

		// Add event listeners for the installationsMega menu itself
		installationsMega.addEventListener( 'mouseleave', () => {
			hideMegaMenu( installationsMega, liHasMegaInstallations );
		} );
	}
}

document.addEventListener( 'DOMContentLoaded', initializeMegaMenus );

function initializePage() {
	const section      = document.querySelector( 'section.simple-hero' );
	const navPrimary   = document.getElementById( 'nav-primary' );
	const navSecondary = document.getElementById( 'nav-secondary' );
	const siteLogo     = document.querySelector( '.site-header-logo' );
	const mobMenuIcon  = document.querySelector( '.mobmenur-container i' );

	if ( section ) {
		const siteHeader = document.querySelector( 'header' );

		if ( siteHeader ) {
			siteHeader.style.backgroundColor = '#fff';

			if ( siteHeader.style.backgroundColor === 'rgb(255, 255, 255)' ) {
				mobMenuIcon.style.color = '#000';
			}
		}

		if ( navPrimary ) {
			navPrimary.classList.add( 'off-black-text' );
		}

		if ( navSecondary ) {
			navSecondary.classList.add( 'off-black-text' );
		}

		if ( siteLogo ) {
			siteLogo.src = '/wp-content/themes/thermal-inmotion/resources/assets/images/thermal-inmotion-stacked-color-logo.png';
		}
	}
}

document.addEventListener( 'DOMContentLoaded', initializePage );

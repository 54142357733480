//import initializedMenu from '../lib/mmenu';
import {basicSplide} from '../lib/splide';
import {tabbedContent} from '../lib/tabbedContent';
import {simpleHero} from '../lib/simpleHero';
import {faqAccordion} from '../lib/faqAccordion';
import {partsTableSearch} from '../lib/partsTableSearch';
import {megaMenu} from '../lib/megaMenu';
import {rentersReorder} from '../lib/rentalsReorder';
import AOS from 'aos';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';

export default {
	init() {
		// JavaScript to be fired on all pages

		// Animate on Scroll Library https://michalsnik.github.io/aos/
		AOS.init();

		// Splide https://splidejs.com/
		basicSplide();

		// Tabbed content
		tabbedContent();

		// FAQ accordion
		faqAccordion();

		// Parts Table Search
		partsTableSearch();

		// Renters List Reorder
		rentersReorder();

		// Mega Menu
		megaMenu();

		// Initiate Fancybox
		$( '[data-fancybox]' ).fancybox( {} );

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired

		// Simple hero helper
		simpleHero();
	},
};

function initializeTabs() {
	const tabs              = document.querySelectorAll( '.selector' );
	const contentContainers = document.querySelectorAll( '.services__single' );

	function showContent( index ) {
		contentContainers.forEach( ( container, i ) => {
			if ( i === index ) {
				container.style.display = 'grid';
			} else {
				container.style.display = 'none';
			}
		} );

		contentContainers[ index ].classList.add( 'fade-in' );
	}

	if ( tabs[ 0 ] ) {
		tabs[ 0 ].classList.add( 'active' );

		contentContainers[ 0 ].classList.add( 'active' );

		tabs.forEach( ( tab, index ) => {
			tab.addEventListener( 'click', () => {
				tabs.forEach( ( t ) => t.classList.remove( 'active' ) );
				tab.classList.add( 'active' );

				contentContainers.forEach( ( container ) => container.classList.remove( 'active' ) );
				contentContainers[ index ].classList.add( 'active' );

				showContent( index );
			} );
		} );

		showContent( 0 );
	}
}

document.addEventListener( 'DOMContentLoaded', initializeTabs );
